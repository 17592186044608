import { defineComponent, inject, watch } from 'vue';
import Header from '@/components/Header.vue';
import RegisterContent from '@/components/RegisterContent.vue';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  name: 'Register',
  components: {
    HeaderComponent: Header,
    RegisterContent: RegisterContent
  },
  setup: function setup() {
    var isMobile = inject('isMobile');
    var t = inject('t');
    var isLoggedIn = useAuthStore().isLoggedIn;
    watch(isLoggedIn, function (v) {
      if (v) {
        window.location.href = '/';
      }
    });
    return {
      isMobile: isMobile,
      t: t
    };
  }
});